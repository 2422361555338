import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

function HeroText({ content, theme, fontWeight, widthCheckbox }) {

  let font

  switch (fontWeight) {
    case "Regular":
      font = "font-noto"
      break
    case "Bold":
      font = "font-noto-bold"
      break
    default:
      font = "font-noto"
  }

  return (
    <div
      className={`content ${
        theme === "light" ? "white-content" : "dark-content"
      } ${font} ${widthCheckbox ? "lg:px-40 md:px-36" : ""}`}
      data-layer-component="hero-text"
    >
      {documentToReactComponents(content.json)}
    </div>
  )
}

export default HeroText
